::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3); */
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: #14a898;
  border-radius: 20px;
}
